<template>
  <div class="list-payroll">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Danh sách bảng lương'">
        <template v-slot:toolbar>
          <div class="row">
            <div>
              <b-dropdown
                size="sm"
                right
              >
                <template slot="button-content">
                  <i
                    style="font-size: 1rem"
                    class="fas fa-cog"
                  ></i>
                  <b>Thao tác</b>
                </template>
                <b-dropdown-item @click="changeRoute('import-payroll')">
                  <i
                    style="font-size: 1rem"
                    class="far fa-file-excel mr-1"
                  ></i>
                  <span>Nhập bảng lương</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </template>
        <template v-slot:preview>
          <div class="mb-5">
            <b-row class="mb-3">
              <b-col cols="3">
                <b-input
                  v-model="searchEmployeeName"
                  size="sm"
                  placeholder="Tên nhân sự"
                />
              </b-col>
              <b-col cols="3">
                <Autosuggest
                  :model="searchPayrollName"
                  :suggestions="filteredPayrolls"
                  :placeholder="'loại bảng lương'"
                  :limit="10"
                  @select="onSelectPayrollType"
                  @change="onInputPayrollType"
                >
                </Autosuggest>
              </b-col>
              <b-col cols="3">
                <datetime
                  class="form-control form-control-sm"
                  v-model="searchSalaryMonth"
                  :config="dpConfigs.month"
                  placeholder="Chọn tháng lương"
                ></datetime>
              </b-col>
              <div class="col">
                <b-button
                  style="width: 70px"
                  variant="primary"
                  size="sm"
                  @click="fetchData"
                >
                  Lọc
                </b-button>
              </div>
            </b-row>
          </div>

          <div
            id="scroll-table"
            style="display: grid; margin-top: 23px;"
            v-if="payrolls.length"
          >
            <b-table
              :fields="fields"
              :items="payrolls"
              :small="true"
              :busy="onLoading"
              responsive
              bordered
              hover
              sticky-header
            >
              <template v-slot:table-busy>
                <vcl-table
                  :speed="5"
                  :animate="true"
                  :columns="10"
                ></vcl-table>
              </template>
              <template v-slot:cell(actions)="row">
                <b-dropdown
                  size="sm"
                  no-caret
                  right
                >
                  <template slot="button-content">
                    <i
                      style="font-size: 1rem"
                      class="flaticon2-settings pr-0"
                    ></i>
                  </template>
                  <b-dropdown-item @click="viewDetail(row.item)">
                    <span style="color: #3f4254; font-size: 12px">
                      <i
                        style="font-size: 1rem"
                        class="fas fa-eye icon-color"
                      ></i>
                      &nbsp; Chi tiết
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </div>

          <b-row>
            <b-col>
              <p
                class="mt-3 text-dark"
                style="font-weight: 500"
              >
                Tổng số:
                {{ totalItem }}
              </p>
            </b-col>
            <div class="d-flex justify-content-end">
              <b-col>
                <b-pagination-nav
                  class="customPagination"
                  v-if="numberOfPage >= 2"
                  :link-gen="linkGen"
                  :number-of-pages="numberOfPage"
                  use-router
                  @change="fetchData"
                  align="right"
                  first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
                  prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
                  next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
                  last-class="page-item-last btn btn-icon btn-sm my-1 "
                  page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
                >
                  <template v-slot:first-text>
                    <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                  </template>
                  <template v-slot:prev-text>
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </template>
                  <template v-slot:next-text>
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </template>
                  <template v-slot:last-text>
                    <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                  </template>
                </b-pagination-nav>
              </b-col>
            </div>
          </b-row>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>

<style lang="scss">
.list-payroll {
  .table-responsive {
    overflow-y: auto;
    max-height: 500px;
  }
  table {
    th {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import { VclTable } from 'vue-content-loading';
import {
  makeToastFaile,
  removeAccents,
  convertPrice,
} from '../../../utils/common';
import moment from 'moment';
import Datetime from 'vue-bootstrap-datetimepicker';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';

export default {
  data() {
    return {
      removeAccents,
      dateConfig: {
        option: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      onLoading: false,
      dpConfigs: {
        month: {
          format: 'MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      fields: [
        {
          key: 'employeeCode',
          label: 'Mã nhân sự',
          thStyle: { textAlign: 'center' },
          class: 'text-center',
        },
        {
          key: 'fullName',
          label: 'Tên nhân sự',
          thStyle: { textAlign: 'center' },
        },
        {
          key: 'name',
          label: 'Tên bảng lương',
          thStyle: { textAlign: 'center' },
        },
        {
          key: 'type',
          label: 'Loại',
          thStyle: { textAlign: 'center' },
          class: 'text-center',
        },
        {
          key: 'salaryMonth',
          label: 'Tháng lương',
          formatter: (value) => moment(value, 'YYYY-MM-dd').format('MM-YYYY'),
          class: 'text-center',
        },
        {
          key: 'createdBy',
          label: 'Người tạo',
          class: 'text-center',
        },
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          thStyle: { textAlign: 'center' },
          formatter: (value) =>
            moment(value).utc().format('DD-MM-YYYY HH:mm:ss'),
          class: 'text-right',
        },
        { key: 'kind', label: 'Loại dòng' },
        { key: 'position', label: 'Vị trí' },
        {
          key: 'positionSalary',
          label: 'Lương vị trí',
          formatter: (value) => convertPrice(value),
        },
        {
          key: 'insurancePaidSalary',
          label: 'Lương đóng bảo hiểm',
          formatter: (value) => convertPrice(value),
        },
        { key: 'standardWorkingDayHour', label: 'Giờ công chuẩn (1 ngày)' },
        { key: 'standardWorkingHour', label: 'Giờ công chuẩn' },
        {
          key: 'hourlySalary',
          label: 'Lương theo giờ',
          formatter: (value) => convertPrice(value),
        },
        {
          key: 'realisticWorkingHours',
          label: 'Giờ làm việc theo ca thực tính',
        },
        {
          key: 'baseSalary',
          label: 'Lương hành chính',
          formatter: (value) => convertPrice(value),
        },
        { key: 'workExtraHours', label: 'Giờ làm thêm' },
        {
          key: 'workExtraPay',
          label: 'Lương làm thêm',
          formatter: (value) => convertPrice(value),
        },
        { key: 'holidayOvertimeHours', label: 'Giờ làm thêm lễ' },
        {
          key: 'holidayPay',
          label: 'Lương làm thêm ngày lễ',
          formatter: (value) => convertPrice(value),
        },
        { key: 'overTimeHours', label: 'Giờ tăng ca' },
        {
          key: 'overTimePay',
          label: 'Lương tăng ca',
          formatter: (value) => convertPrice(value),
        },
        {
          key: 'holidayHoursPay',
          label: 'Giờ lễ hưởng lương',
          formatter: (value) => convertPrice(value),
        },
        { key: 'workingHoliday', label: 'Công lễ' },
        {
          key: 'holidaySalary',
          label: 'Lương lễ',
          formatter: (value) => convertPrice(value),
        },
        {
          key: 'dayOffPay',
          label: 'Ngày nghỉ có lương',
          formatter: (value) => convertPrice(value),
        },
        { key: 'leavePay', label: 'Số công chuẩn' },
        {
          key: 'standardWorkingNumber',
          label: 'Lương phép',
          formatter: (value) => convertPrice(value),
        },
        {
          key: 'periodSalary',
          label: 'Mức lương kỳ',
          formatter: (value) => convertPrice(value),
        },
        {
          key: 'positionAllowance',
          label: 'Phụ cấp chức vụ',
          formatter: (value) => convertPrice(value),
        },
        {
          key: 'attendanceAllowance',
          label: 'Phụ cấp chuyên cần',
          formatter: (value) => convertPrice(value),
        },
        {
          key: 'dualJobAllowance',
          label: 'Phụ cấp kiêm nhiệm',
          formatter: (value) => convertPrice(value),
        },
        {
          key: 'transportationAllowance',
          label: 'Phụ cấp gửi xe',
          formatter: (value) => convertPrice(value),
        },
        {
          key: 'appearanceAllowance',
          label: 'Phụ câp tác phong đẹp',
          formatter: (value) => convertPrice(value),
        },
        {
          key: 'customerSatisfactionAllowance',
          label: 'Phụ cấp Khách hàng hài lòng',
          formatter: (value) => convertPrice(value),
        },
        {
          key: 'totalIncome',
          label: 'Tổng thu nhập',
          formatter: (value) => convertPrice(value),
        },
        {
          key: 'additionAccrual',
          label: 'Phát sinh cộng',
          formatter: (value) => convertPrice(value),
        },
        {
          key: 'intoAmount',
          label: 'Thành tiền',
          formatter: (value) => convertPrice(value),
        },
        {
          key: 'deductionAccrual',
          label: 'Phát sinh trừ',
          formatter: (value) => convertPrice(value),
        },
        {
          key: 'personalIncomeTax',
          label: 'Thuế TNCN',
          formatter: (value) => convertPrice(value),
        },
        {
          key: 'unionDues',
          label: 'Khấu trừ CĐ',
          formatter: (value) => convertPrice(value),
        },
        {
          key: 'shuInsuranceDeduction',
          label: 'Khấu trừ BHXH, BHYT, BHTN',
          formatter: (value) => convertPrice(value),
        },
        {
          key: 'netPay',
          label: 'Thực nhận',
          formatter: (value) => convertPrice(value),
        },
        { key: 'bankAccountNumber', label: 'Số tài khoản ngân hàng' },
        { key: 'bankAccountName', label: 'Tên tài khoản ngân hàng' },
        { key: 'bankName', label: 'Tên ngân hàng' },
        {
          key: 'totalAmount',
          label: 'Tổng tiền',
          formatter: (value) => convertPrice(value),
        },
        {
          key: 'personalSalaryTransferred',
          label: 'Lương chuyển qua TK cá nhân',
          formatter: (value) => convertPrice(value),
        },
        {
          key: 'businessSalaryTransferred',
          label: 'Lương chuyển qua TK doanh nghiệp',
          formatter: (value) => convertPrice(value),
        },
        {
          key: 'dependent',
          label: 'Người phụ thuộc',
        },
        {
          key: 'selfAllowance',
          label: 'Giảm trừ bản thân',
          formatter: (value) => convertPrice(value),
        },
        {
          key: 'holidayLeave',
          label: 'Nghỉ lễ',
        },
        {
          key: 'holidayMoney',
          label: 'Tiền ngày lễ',
          formatter: (value) => convertPrice(value),
        },
        {
          key: 'workOnHoliday',
          label: 'Làm ngày lễ',
        },
        {
          key: 'periodicSalary',
          label: 'Mức lương kì',
          formatter: (value) => convertPrice(value),
        },
        {
          key: 'supplementSalary',
          label: 'Lương bổ sung',
          formatter: (value) => convertPrice(value),
        },
        {
          key: 'nightWorkingHour',
          label: 'Giờ làm việc thực tính ban đêm',
        },
        {
          key: 'nightShiftSalary',
          label: 'Lương ca đêm',
          formatter: (value) => convertPrice(value),
        },
        {
          key: 'taxFreeAmount',
          label: 'Số tiền ko đóng thuế',
          formatter: (value) => convertPrice(value),
        },
      ],
      payrolls: [],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      page: 1,
      totalItem: 0,
      selectedRowExcel: 'all',
      selectedListExcel: 'current',
      showHeaderExcel: false,
      indeterminate: false,
      allSelected: false,
      optionsHeader: [],
      selectedHeader: [],
      optionsHeaderDefault: [],
      numberOfPage: 1,
      searchLastName: '',
      searchEmployeeName: '',
      searchPayrollId: null,
      searchPayrollName: '',
      searchSalaryMonth: null,
      payrollTypeOptions: [
        {
          id: 1,
          name: 'Theo giờ công chuẩn',
        },
        {
          id: 2,
          name: 'Theo ngày công chuẩn làm đêm',
        },
        {
          id: 3,
          name: 'Theo giờ công chuẩn làm ban đêm',
        },
        {
          id: 4,
          name: 'Theo ngày công chuẩn import',
        },
        {
          id: 5,
          name: 'Theo ngày công chuẩn',
        },
        {
          id: 6,
          name: 'Theo miễn chấm công',
        },
        {
          id: 7,
          name: 'Theo báo cáo mục tiêu',
        },
      ],
      filteredPayrolls: [],
    };
  },
  components: {
    Autosuggest,
    Datetime,
    KTCodePreview,
    VclTable,
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Nhân sự', route: '/payrolls' },
      { title: 'Bảng lương' },
    ]);
  },
  methods: {
    onSelectPayrollType(option) {
      this.searchPayrollId = option.item.id;
      this.searchPayrollName = option.item.name;
    },
    onInputPayrollType(text = '') {
      const filteredData = this.payrollTypeOptions.filter((item) => {
        return (
          removeAccents(item.name)
            .toLowerCase()
            .indexOf(removeAccents(text.toLowerCase())) > -1
        );
      });

      this.filteredPayrolls = [...filteredData];
    },
    changeRoute(namePath) {
      this.$router.push({
        name: `${namePath}`,
      });
    },
    fetchData: function () {
      this.onLoading = true;
      this.page = this.$route.query.page || 1;

      const params = {
        page: this.page,
        limit: 10,
        searchEmployeeName: this.searchEmployeeName,
        searchPayrollId: this.searchPayrollId,
        searchSalaryMonth: this.searchSalaryMonth,
      };
      ApiService.query('/human-resources/payrolls', {
        params: params,
      }).then(({ data }) => {
        if (data.status) {
          this.payrolls = [];
          const dataReq = data.data;
          this.totalItem = dataReq.totalItems;
          this.payrolls = dataReq.result;
          this.numberOfPage = dataReq.totalPages;
        } else {
          makeToastFaile(data.message);
        }
        this.onLoading = false;
      });
    },
  },
};
</script>
